import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const DateFormat = new Intl.DateTimeFormat("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric",
});

const format = (markdown, metadata) => {
  if (!metadata) return markdown;

  let modifiedMarkdown = "";

  const { title, authors, date } = metadata;

  if (title) {
    modifiedMarkdown += `# ${title}\n\n`;
  }

  if (date) {
    modifiedMarkdown += `*${DateFormat.format(date)}*\n\n`;
  }

  if (authors && authors.length > 0) {
    const formattedAuthors = authors.map(
      ({ firstName, lastName, linkedInShortURL }) =>
        `[${firstName} ${lastName}](https://www.linkedin.com/in/${linkedInShortURL}/)`,
    );

    let stringifiedAuthors = "";

    if (authors.length === 1) {
      stringifiedAuthors = `${formattedAuthors[0]}`;
    } else {
      stringifiedAuthors =
        formattedAuthors.slice(0, formattedAuthors.length - 1).join(", ") +
        (formattedAuthors.length > 2 ? ", and " : " and ") +
        formattedAuthors[formattedAuthors.length - 1];
    }

    modifiedMarkdown += `*By ${stringifiedAuthors}*\n\n`;
  }

  modifiedMarkdown += `---\n${markdown}`;

  return modifiedMarkdown;
};

export default function Markdown({
  md,
  onError,
  onSuccess,
  urlTransform,
  metadata,
}) {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(md)
      .then((response) => {
        if (response.ok) return response.text();

        throw new Error("invalid response status code");
      })
      .then((text) => setMarkdown(text))
      .then((_) => onSuccess())
      .catch((_) => {
        if (onError) onError();
      });
  }, [md]);

  return (
    <>
      {markdown && (
        <>
          <div className="ml-[20%] mr-[20%] flex justify-center my-28 p-4 lg:p-16">
            <ReactMarkdown
              children={format(markdown, metadata)}
              urlTransform={urlTransform}
              className="prose lg:prose-lg xl:prose-xl"
              remarkPlugins={[remarkGfm]}
              components={{
                hr: ({ node, ...props }) => (
                  <hr className="border-b-2 justify-center w-[100%] border-[#0071FE]" />
                ),
                h1: ({ node, ...props }) => (
                  <h1 style={{ color: "#0071FE" }} {...props} />
                ),
                h2: ({ node, ...props }) => (
                  <h2 style={{ color: "#0071FE" }} {...props} />
                ),
                h3: ({ node, ...props }) => (
                  <h3 style={{ color: "#0071FE" }} {...props} />
                ),
                h4: ({ node, ...props }) => (
                  <h4 style={{ color: "#0071FE" }} {...props} />
                ),
                h5: ({ node, ...props }) => (
                  <h5 style={{ color: "#0071FE" }} {...props} />
                ),
                h6: ({ node, ...props }) => (
                  <h6 style={{ color: "#0071FE" }} {...props} />
                ),
                img: ({ node, ...props }) => (
                  <img {...props} className="mx-auto" />
                ),
                section: ({ node, ...props }) => {
                  const { children, ...rest } = props;

                  if (rest.className !== "footnotes") {
                    return <section {...rest}>{children}</section>;
                  }

                  const orderedList = React.Children.map(children, (child) => {
                    if (child.type === "ol") return child;
                  });

                  return (
                    <>
                      <hr className="border-b-2 justify-center w-[100%] border-[#0071FE]" />
                      <section {...rest}>
                        <div className="text-xs">{orderedList}</div>
                      </section>
                    </>
                  );
                },
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
