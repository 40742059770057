import Innovacer from "../../assets/posts/20250129_innovacer.md";
import DeepSeek from "../../assets/posts/20250131_deepseek.md";

const AuthorRashmi = {
  firstName: "Rashmi",
  lastName: "Gopinath",
  linkedInShortURL: "rashmigopinath",
};

const AuthorKristin = {
  firstName: "Kristin",
  lastName: "Baker Spohn",
  linkedInShortURL: "kristin-baker-spohn-8953762",
};

const AuthorNickhil = {
  firstName: "Nickhil",
  lastName: "Nabar",
  linkedInShortURL: "nnabar",
};

const AuthorStephanie = {
  firstName: "Stephanie",
  lastName: "Sphen",
  linkedInShortURL: "stephaniephen",
};

const PostIndex = [
  {
    title:
      "Unlocking Healthcare AI's Potential: Why We're Excited About Innovaccer",
    date: new Date("2025-01-30T00:00:00"),
    href: Innovacer,
    authors: [AuthorRashmi, AuthorKristin, AuthorStephanie],
  },
  {
    title:
      "Landmark Moment in the Evolution of Foundational LLMs: Rise of Open-Source Models and Tailwinds for the AI Application Layer",
    date: new Date("2025-02-07T00:00:00"),
    href: DeepSeek,
    authors: [AuthorRashmi, AuthorNickhil],
  }
];

const slugify = (value) => {
  return (
    value.toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
  );
}

export function findOneBySlug(slug) {
  const needle = PostIndex.find(
    (post) => slugify(post.title) === slug
  );

  if (!needle) return null;

  return { ...needle, slug }
}

export function list() {
  return PostIndex.map(
    ({ title, ...rest }) => ({ ...rest, title, slug: slugify(title) })
  )
};