import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";
import { list } from "./posts";

const DateFormat = new Intl.DateTimeFormat("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric",
});

const BlogCard = ({ title, slug, date, authors }) => {
  return (
    <div className="flex items-start flex-col">
      <Link
        key={slug}
        to={`/blog/${slug}`}
        className="text-4xl max-w-[50%]"
        style={{ color: "#0071FE" }}
      >
        {title}
      </Link>
      <div className="mt-6 border-b-2 justify-center w-[20%] border-[#0071FE]"></div>
      <div className="mt-6">{DateFormat.format(date)}</div>
    </div>
  );
};

export default function BlogIndex() {
  console.log(list())
  return (
    <Layout>
      <div className="flex flex-col justify-center gap-8 my-28 p-4 lg:p-16">
        {
          list()
          .sort(({ date: leftDate }, { date: rightDate }) =>
            leftDate < rightDate ? 1 : -1,
          )
          .map(({ slug, ...metadata }) => {
            return <BlogCard key={slug} slug={slug} {...metadata} />;
          })}
      </div>
    </Layout>
  );
}
