import React from "react";
import md from "../assets/legal_docs/user_agreement.md";
import Markdown from "../Components/Markdown";
import Layout from "../Components/Layout";

export default function UserAgreement() {
  return (
    <Layout>
      <Markdown md={md} />
    </Layout>
  );
}
