import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function Layout({ children }) {
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className="Header">
        <Header></Header>
      </div>
      <div className="flex-grow" style={{ fontFamily: "SharpSans-Medium" }}>
        {children}
      </div>
      <Footer />
    </div>
  );
}
