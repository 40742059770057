import React, { useState } from "react";
import Markdown from "../../Components/Markdown";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import Layout from "../../Components/Layout";

import { findOneBySlug } from "./posts";

const Disclosure = (props) => {
  return (
    <div className="flex justify-center my-28 p-4 lg:p-16">
      <div className="text-sm mt-20 space-y-4 justify-center prose">
        <p>Disclosure</p>
        <p>
          This post expresses the views of BAM Corner Point (together with its
          affiliates, “BAM Corner Point”). It is not research and should not be
          treated as research. It does not represent valuation judgments with
          respect to any product, issuer, security, or sector that may be
          described or referenced herein and does not represent a formal or
          official view of BAM Corner Point. This post is not intended to, and
          does not, relate specifically to any investment strategy or product
          that BAM Corner Point offers.
        </p>
        <p>
          This post has been prepared solely for informational purposes. It
          should not be viewed as a recommendation or a solicitation of an offer
          to buy or sell any securities or to adopt any strategy. Any strategy
          discussed or implicated by this post may be unsuitable for investors
          depending on their specific investment objectives and financial
          situation. There can be no assurance that an investment strategy will
          be successful. Historic market trends are not reliable indicators of
          actual market behavior or future performance, which may differ
          materially.
        </p>
        <p>
          Although information in this post has been developed internally and/or
          obtained from sources believed to be reliable, neither BAM Corner
          Point nor any other person guarantees the accuracy, adequacy, or
          completeness of such information. Nothing contained in the post
          constitutes investment, legal, tax or other advice nor is it to be
          relied upon in making an investment or other decision.
        </p>
        <p>
          The information in this post, including any projections or other
          forward-looking statements regarding future events, targets,
          forecasts, or expectations, is only as current as of the date
          indicated and may be superseded by subsequent market events or for
          other reasons. Neither BAM Corner Point nor any other person assumes
          any duty to update forward-looking statements. There can be no
          assurance as to any future event or outcome, and actual events or
          outcomes may be significantly different from those contemplated in the
          post.
        </p>
        <p>
          It should not be assumed that BAM Corner Point has made or will make
          investment recommendations in the future that are consistent with the
          views expressed herein or use any or all of the techniques or methods
          of analysis described herein in managing client or proprietary
          accounts. BAM Corner Point may make investment recommendations, hold
          positions, or engage in transactions that are not consistent with the
          information and views expressed in this post.
        </p>
        <p>
          Customer names are provided for illustrative purposes only. No
          representation is made that the companies listed either endorse or
          support the statements on this page or that they represent the success
          or profitability of Innovaccer. No representation or warranty, express
          or implied, is made or given by or on behalf of BAM Corner Point, or
          any other person as to the accuracy and completeness or fairness of
          the information contained in this post and no responsibility or
          liability is accepted for any such information. By accessing this
          post, the recipient acknowledges its understanding and acceptance of
          the foregoing statement.
        </p>
      </div>
    </div>
  );
};

export default function BlogPost() {
  const { slug } = useParams();

  const [showDisclosure, setShowDisclosure] = useState(false);

  const navigate = useNavigate();

  const post = findOneBySlug(slug);

  if (!post) {
    return <Navigate to="/blog" />;
  }

  const { href, ...rest } = post;

  return (
    <Layout>
      <Markdown
        md={href}
        onError={() => navigate("/")}
        onSuccess={() => setShowDisclosure(true)}
        urlTransform={(url, key, node) => {
          if (node.tagName !== "img") return url;

          return `/static/images/${url}`;
        }}
        metadata={rest}
      />
      {showDisclosure && <Disclosure />}
    </Layout>
  );
}
