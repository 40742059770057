import React from "react";
import md from "../assets/legal_docs/privacy_policy.md";
import Markdown from "../Components/Markdown";
import Layout from "../Components/Layout";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Markdown md={md} />
    </Layout>
  );
}
